import { template as template_db33a247a4224604b781e4cbc184f2da } from "@ember/template-compiler";
const SidebarSectionMessage = template_db33a247a4224604b781e4cbc184f2da(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
