import { template as template_a327599aae1a492eac00e06bd67e9a1a } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_a327599aae1a492eac00e06bd67e9a1a(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
